.font-futura { 
  font-family: "Futura";
}

.font-inter {
  font-family: "Inter";
}

.fw-600 {
	font-weight: 600;
}