.breadcrumb {
  padding: 0.5rem 8rem !important;
  margin-bottom: 0 !important;
  background-color: white !important;
}

.breadcrumb-item {
  display: flex !important;
  align-items: center !important;
  padding-top: 0 !important;

  span {
    font-size: 12px !important;
    cursor: 'pointer' !important;
  }
}
