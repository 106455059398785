.eon-login-title {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-bottom: 24px;
  align-items: center;

  span {
    color: #344054;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  img {
    width: 110px;
    min-width: 110px;
  }
}