.custom-button {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 6px 14px 6px 12px;
  font-weight: 400 !important;

  svg, img {
    margin-right: 4px;
    width: 14px;
    height: 14px;
  }

  &__white {
    border: 1px solid #d0d5dd !important;
    color: #344054 !important;
    background-color: #ffffff !important;
    transition: background-color 0.1s linear;

    &:hover {
      border-color: darken(#d0d5dd, 10%) !important;
      background-color: darken(#ffffff, 20%) !important;
      color: #ffffff !important;
    }
  }

  &__black {
    border-color: var(--primary) !important;
    color: #ffffff;
    background-color: var(--primary) !important;
    transition: background-color 0.1s linear;

    &:hover {
      border-color: lighten(#252525, 10%) !important;
      background-color: lighten(#252525, 10%) !important;
    }
  }

  &__grey {
    border-color: #9B9B9B;
    color: #ffffff;
    background-color: #9B9B9B;
    transition: background-color 0.1s linear;

    &:hover {
      border-color: lighten(#9B9B9B, 10%);
      background-color: lighten(#9B9B9B, 10%);
    }
  }

  &__borderless {
    border: none !important;
    color: #667085 !important;
    background-color: transparent !important;
    transition: background-color 0.1s linear;

    &:active,
    &:hover {
      background-color: transparent !important;
      color: lighten(#667085, 10%) !important;
      border-color: transparent !important;
    }
  }
}

.full-width-button {
  border-radius: 8px !important;
  justify-content: center;
  padding: 10px 18px;
  width: 100%;
}

.btn-sm {
  font-size: 14px !important;
  line-height: 20px !important;
}

.btn-lg {
  font-size: 16px !important;
  line-height: 24px !important;
}