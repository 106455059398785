@import "../../stylesheets/layout/layout-variables";

.closed-sidebar{
  .custom-header-logo {
    padding-left: 1.5rem !important;
  }
}

.custom-header-logo {
  padding-left: calc( $app-sidebar-width + 1.5rem ) !important;
}